import api from 'src/store/api'
import http from 'src/store/http'
import logger from 'src/plugins/logger'
import server from 'src/store/server'
import transform from 'src/utils/transform'

import Organization from 'src/internal/store/utils/organization'
import User from 'src/internal/store/utils/user'

import { fireanalytics, fireauth, firecollection } from 'src/store/firebase'

import actionNames from './actionNames'
import { copyObject, createObject } from '@/utils/objects'

const actions = {
  watch({ commit, dispatch }) {
    const autoAuth = async (user) => dispatch('autoAuth', user)
    const errorCallback = (error) => {
      const { message = 'Unknown Auth Error' } = error

      fireanalytics.event('exception', {
        category: 'Auth',
        description: 'Auto Auth',
        label: `Auto Auth Failed with Error "${error.message}"`,
      })

      commit('tools/snackbar', true, { root: true })
      commit('tools/snack', { message, status: 'error' }, { root: true })
    }

    return fireauth.onAuthStateChanged(autoAuth, errorCallback)
  },
  signIn({ commit }) {
    commit(actionNames.SIGN_IN_REQUESTED)

    return fireauth.signInWithPopup().catch((error) => {
      if (error.code === 'auth/network-request-failed') {
        error.message = 'Network Unavailable'
      }

      const { message } = error

      commit('tools/snackbar', true, { root: true })
      commit('tools/snack', { message, status: 'error' }, { root: true })

      fireanalytics.event('exception', {
        category: 'Auth',
        description: 'Sign In',
        label: `Sign In Failed with Error "${message}"`,
      })
    })
  },

  async getUserByEmail(context, email) {
    return firecollection.get('person', email)
  },
  async getPersonDocuments(context, catcher) {
    return firecollection.collection('person', catcher)
  },
  async getExternalUsers(context, catcher) {
    const persons = await firecollection.collection('person', catcher)
    return persons.filter((person) => person.access && person.access.role === 'external')
  },

  async getMessages(context, email) {
    return firecollection.get('message', email)
  },

  async upsertFirestore(context, { collection, docId, doc }) {
    const options = { merge: true }
    const savedRecord = await firecollection.get(collection, docId)

    if (savedRecord) {
      return firecollection.set(collection, savedRecord.docId, doc, { merge: true })
    }

    return firecollection.set(collection, docId, doc, options)
  },
  async updateUserSettings({ dispatch }, { doc, docId }) {
    const collection = 'person'

    return dispatch('upsertFirestore', { collection, docId, doc })
  },
  async upsertUser({ dispatch }, personUser) {
    const collection = 'person'
    const doc = User.mapUser(personUser)
    const docId = personUser.email

    return dispatch('upsertFirestore', { collection, docId, doc })
  },
  async deleteUser({ dispatch }, personUser) {
    if (!personUser.access) {
      personUser.access = { active: false }
    } else {
      personUser.access.active = false
    }

    const collection = 'person'
    const doc = User.mapUser(personUser)
    const docId = personUser.email

    return dispatch('upsertFirestore', { collection, docId, doc })
  },

  handleUpdateToUserSettings({ commit, dispatch, getters, state }, { changes, fnSuccess }) {
    const { user } = state
    const { inAliasMode } = getters
    const currentSettings = inAliasMode ? getters.settings : getters.selfSettings
    const docId = inAliasMode && !changes.alias ? user.aliasId : user.docId

    const settings = Object.keys(changes).reduce((newObject, key) => {
      newObject[key] = changes[key]
      return newObject
    }, currentSettings)
    const doc = { settings }
    const dataToSubmit = { doc, docId }
    const fnSuccessDefault = () => {
      const message = 'Saved'
      const status = 'success'

      commit('tools/snackbar', true, { root: true })
      commit('tools/snack', { message, status }, { root: true })
    }

    return dispatch('updateUserSettings', dataToSubmit)
      .then(fnSuccess || fnSuccessDefault)
      .catch((error) => {
        const { message = 'Unknown Error ' } = error
        const status = 'error'

        commit('tools/snackbar', true, { root: true })
        commit('tools/snack', { message, status }, { root: true })
      })
  },

  async updateAliasMode({ commit, dispatch, getters }, alias) {
    commit(actionNames.SET_ALIAS_MODE_REQUESTED)

    const isSettingAliasModeOn = (alias && alias.active && 'On') || 'Off'

    fireanalytics.event('goal_initialization', {
      category: 'Alias',
      description: `Set Alias Mode ${isSettingAliasModeOn}`,
      label: `Set Alias Mode ${isSettingAliasModeOn} Requested`,
    })

    const hasRightsToUpdate = getters.hasAliasPermission

    const catcher = (err) => {
      const error = http.catch(err)
      const errorMessage = error.message || 'Unknown Error'

      // Update state with error.
      commit(actionNames.SET_ALIAS_MODE_FAILED, error)

      // Log error to analytics.
      fireanalytics.event('exception', {
        category: 'Alias',
        description: `Set Alias Mode ${isSettingAliasModeOn}`,
        label: `Set Alias Mode ${isSettingAliasModeOn} Failed with Error "${error.message}"`,
      })

      // Inform user of error.
      commit('tools/snackbar', true, { root: true })
      commit('tools/snack', { message: errorMessage, status: 'error' }, { root: true })

      return error
    }

    // If user is not authorized to update alias mode, return error.
    if (!hasRightsToUpdate) {
      const error = { code: '403', layer: 'app', message: 'Forbidden' }
      return catcher({ error })
    }

    // Update the alias settings on the user record.
    const fnSuccess = () => {
      const message = 'Updated Alias Settings'
      const status = 'success'

      // Inform user of success.
      commit('tools/snackbar', true, { root: true })
      commit('tools/snack', { message, status }, { root: true })

      // Update state with success.
      commit(actionNames.SET_ALIAS_MODE_SUCCEEDED, alias)

      // Log success to analytics.
      fireanalytics.event('goal_completion', {
        category: 'Alias',
        description: `Set Alias Mode ${isSettingAliasModeOn}`,
        label: `Set Alias Mode ${isSettingAliasModeOn} Succeeded`,
      })

      // Reload page to update view.
      window.location = '/'
    }

    const changes = { alias }

    return dispatch('handleUpdateToUserSettings', { changes, fnSuccess })
  },

  async setDemoMode({ commit }, newDemoMode) {
    commit('demoMode', newDemoMode)

    await api.set(newDemoMode)

    return newDemoMode
  },

  async updateDemoMode({ commit, dispatch, rootGetters }, demo) {
    commit(actionNames.SET_DEMO_MODE_REQUESTED)

    const isSettingDemoModeOn = demo === true || (typeof demo === 'object' && demo.active === true)
    const statusWord = isSettingDemoModeOn ? 'On' : 'Off'

    fireanalytics.event('goal_initialization', {
      category: 'Demo',
      description: `Set Demo Mode ${statusWord}`,
      label: `Set Demo Mode ${statusWord} Requested`,
    })

    const hasRightsToUpdate = rootGetters['auth/isDemoWriter']

    const catcher = (err) => {
      const error = http.catch(err)
      const errorMessage = error.message || 'Unknown Error'

      // Update state with error.
      commit(actionNames.SET_DEMO_MODE_FAILED, error)

      // Log error to analytics.
      fireanalytics.event('exception', {
        category: 'Demo',
        description: `Set Demo Mode ${statusWord}`,
        label: `Set Demo Mode ${statusWord} Failed with Error "${error.message}"`,
      })

      // Inform user of error.
      commit('tools/snackbar', true, { root: true })
      commit('tools/snack', { message: errorMessage, status: 'error' }, { root: true })

      return error
    }

    if (!hasRightsToUpdate) {
      const error = { code: '403', layer: 'app', message: 'Forbidden' }
      return catcher({ error })
    }

    const fnSuccess = async () => {
      commit(actionNames.SET_DEMO_MODE_SUCCEEDED, demo)

      fireanalytics.event('goal_completion', {
        category: 'Demo',
        description: `Set Demo Mode ${isSettingDemoModeOn}`,
        label: `Set Demo Mode ${isSettingDemoModeOn} Succeeded`,
      })

      await dispatch('setDemoMode', demo.active)

      window.location = '/'
    }

    const changes = { demo }

    return dispatch('handleUpdateToUserSettings', { changes, fnSuccess })
  },
  /**
   * Gets a Tableau token from the server.
   * @returns {ServerResponse}
   */
  async getTableauToken() {
    return server.generate('tableau')
  },
  setDataAccess({ dispatch }, person) {
    if (!person) return false

    http.setRequester(person.docId)

    const inDemoMode = User.inDemoMode(person)

    return dispatch('setDemoMode', inDemoMode)
  },

  /**
   * Fetches collection and saves it to store by its endpointName.
   * @return items {Array} Result of requestCollection, which traces to tools/successHandler action.
   */
  async getCollectionByName({ dispatch }, { endpointName, moduleName, reportKey, user }) {
    const applyFilters = endpointName === moduleName
    const requestReport = { applyFilters, endpointName, moduleName, reportKey }

    const requestCollection = async () => {
      const fetch = await dispatch('tools/request', { moduleName, params: { reportKey }, user }, { root: true })
      return dispatch('tools/render', fetch, { root: true })
    }

    return dispatch('tools/getCollection', { requestCollection, requestReport }, { root: true })
  },
  async getOrganizations({ dispatch }, { moduleName, user }) {
    const endpointName = 'organizations'
    const reportKey = 'organization'
    return dispatch('getCollectionByName', { endpointName, moduleName, reportKey, user })
  },
  async getOverviews({ dispatch }, { moduleName, user }) {
    const endpointName = 'overviews'
    const reportKey = 'overview'
    return dispatch('getCollectionByName', { endpointName, moduleName, reportKey, user })
  },
  /**
   * Gets persons from API, plus users from Firestore, to make PersonUsers.
   * @param {Object} context
   * @param {Object} data
   * @returns {PersonUser[]} PersonUsers
   */
  async getPersonUsers({ dispatch }, { moduleName, user }) {
    const endpointName = 'persons'
    const reportKey = 'person'
    return dispatch('getCollectionByName', { endpointName, moduleName, reportKey, user })
  },
  async getProjects({ dispatch }, { moduleName, user }) {
    const endpointName = 'projects'
    const reportKey = 'project'
    return dispatch('getCollectionByName', { endpointName, moduleName, reportKey, user })
  },
  async getServices({ dispatch }, { moduleName, user }) {
    const endpointName = 'services'
    const reportKey = 'service'
    return dispatch('getCollectionByName', { endpointName, moduleName, reportKey, user })
  },
  async getTimes({ dispatch }, { moduleName, user }) {
    const endpointName = 'times'
    const reportKey = 'time'
    return dispatch('getCollectionByName', { endpointName, moduleName, reportKey, user })
  },
  async getTimeTypes({ dispatch }, { moduleName, user }) {
    const endpointName = 'timeTypes'
    const reportKey = 'timeType'
    return dispatch('getCollectionByName', { endpointName, moduleName, reportKey, user })
  },

  getOrgData({ dispatch, rootGetters, rootState }, reportId) {
    if (reportId) {
      return rootGetters['tools/organization']
    }

    const { auth } = rootState
    const { user } = auth
    const moduleName = 'organizations'

    return dispatch('getOrganizations', { moduleName, user })
  },

  async getAllTeams({ commit }, publisher) {
    const { publisherId } = publisher
    const teamsCatcher = () => commit('GET_TEAMS_FAILED', publisher)

    return api.getTeams(publisherId).catch(teamsCatcher)
  },
  findNetworkTeams(context, { publisherId, networks }) {
    const findConfiguredTeams = (network) => {
      const { organization, team: configuredTeams } = network
      return String(organization.id) === String(publisherId) ? configuredTeams : false
    }

    return networks.find(findConfiguredTeams) || []
  },
  hasAllTeamsSet(context, teams) {
    if (!Array.isArray(teams)) return false
    return !!teams.find((team) => team.id === '-1')
  },
  async getConfiguredTeams({ dispatch }, { publisher, networks }) {
    const { publisherId } = publisher
    const publisherNetworkData = { publisherId, networks }
    const network = await dispatch('findNetworkTeams', publisherNetworkData)
    const { team: networkTeams } = network
    const hasAllTeamsSet = await dispatch('hasAllTeamsSet', networkTeams)

    if (hasAllTeamsSet) {
      return dispatch('getAllTeams', publisher)
    }

    const teamIds = networkTeams.map((networkTeam) => networkTeam.id)
    const content = api.getTeamsByPublisherId(publisherId, teamIds)

    return { content, error: false }
  },
  async setTeamsOnPublisher({ dispatch }, requestData) {
    const { isInternalUser, publisher } = requestData
    const success = (teamsResponse) => {
      const { content: teams, error: teamError } = teamsResponse

      publisher.teams = teamError ? teamsResponse : teams

      return publisher
    }

    if (isInternalUser) {
      return dispatch('getAllTeams', publisher).then(success)
    }

    return dispatch('getConfiguredTeams', requestData).then(success)
  },
  setTeamsOnPublishers({ dispatch }, { isInternalUser, publishersResponse, user }) {
    const { network: networks = [{}] } = user
    const { content: publishers, error: publisherError } = publishersResponse

    return publisherError || Promise.all(publishers.map(async (publisher) => {
      const getPublisherWithoutTeams = () => {
        const { hasTeam } = publisher
        return !hasTeam && Promise.resolve(publisher)
      }
      const getPublisherWithTeams = () => {
        const requestData = { isInternalUser, publisher, networks }
        return dispatch('setTeamsOnPublisher', requestData)
      }

      return getPublisherWithoutTeams() || getPublisherWithTeams()
    }))
  },

  async getClients({ dispatch, rootGetters }, orgId) {
    const endpointName = 'clients'
    const moduleName = 'organizations'
    const reportKey = rootGetters['tools/reportKey']
    const root = { root: true }
    const applyFilters = false
    const requestReport = { applyFilters, endpointName, reportKey }

    const handler = await dispatch('tools/requestHandler', requestReport, root)
    const { catcher, success } = handler

    const response = await api.getClients(orgId).catch(catcher)
    const { content: clients, error } = response || {}

    if (error) return catcher(response)

    const items = clients.map((client) => {
      client.text = client.clientName
      client.value = client.clientId

      return client
    })
    const responseReport = { endpointName, items, moduleName, success }

    return dispatch('tools/requestSuccess', responseReport, root)
  },

  /**
   * Gets publishers and their teams from the API.
   * @param {Object} context
   * @param {function} context.dispatch
   * @param {Object} data
   * @param {boolean} data.isInternalUser
   * @param {Object} data.user { network, role }
   * @return {Promise<ApiResponse>}
   */
  fetchPublishersByRole({ dispatch }, { isInternalUser, user }) {
    const setTeamsOnPublishers = (publishersResponse) => dispatch('setTeamsOnPublishers', { isInternalUser, publishersResponse, user })
    const makePublisherResponse = (publishers) => {
      if (Array.isArray(publishers)) { // if have publishers, as opposed to an error response
        // @TODO Remove test publisher from array when it's no longer needed.
        const testPublisher = {
          billingCode: 'TEST2022',
          networkCode: 249793494,
          name: 'Test Publisher',
          hasDiscrepancy: false,
          hasHealthAdx: false,
          hasHealthNetwork: false,
          hasProgrammatic: false,
          hasTeam: false,
          hasYieldPartner: false,
          isActive: true,
          publisherId: 249793494,
          unifiedBillingCode: 'TEST2022',
        }

        publishers.push(testPublisher)
      }

      return { content: publishers, totalCount: publishers.length }
    }
    const internalRequest = () => api.getPublishers().then(setTeamsOnPublishers).then(makePublisherResponse)
    const externalRequest = () => {
      const { network: networks = [{}] } = user
      const billingCodes = networks.map((network) => {
        const { organization = {} } = network
        const { code = '' } = organization
        return code
      })

      return api.getPublishersByBillingCodes(billingCodes).then(setTeamsOnPublishers).then(makePublisherResponse)
    }

    return isInternalUser ? internalRequest() : externalRequest()
  },
  /**
   * Gets publishers and their teams from the API, then stores the results.
   * @param {Object} context
   * @param {function} context.dispatch
   * @param {object} context.rootGetters
   * @param {Object} data
   * @param {boolean} data.isInternalUser
   * @param {Object} data.user { network, role }
   * @return {Promise<Publishers|ErrorMessage>}
   */
  async getPublishers({ dispatch, rootGetters }, { isInternalUser, user }) {
    const endpointName = 'publishers'
    const moduleName = 'organizations'
    const reportKey = rootGetters['tools/reportKey']
    const root = { root: true }
    const applyFilters = false
    const requestReport = { applyFilters, endpointName, reportKey }

    const handler = await dispatch('tools/requestHandler', requestReport, root)
    const { catcher, success } = handler

    const response = await dispatch('fetchPublishersByRole', { isInternalUser, user })
    const { content: items, error } = response
    const storePublishers = () => {
      const responseReport = { endpointName, items, moduleName, success }
      return dispatch('tools/requestSuccess', responseReport, root)
    }

    return error ? catcher(error) : storePublishers()
  },
  getOrgConfig(context, catcher) {
    return firecollection.collection('organization', catcher)
  },
  getOrgConfigByBillingCode(context, { billingCodes, catcher }) {
    const promises = billingCodes.map((billingCode) => firecollection.get('organization', billingCode, catcher))

    return Promise.all(promises)
  },
  getOrgRequestsByRole({ commit, dispatch }, user) {
    const isInternalUser = user.access?.role === 'internal'
    const { network: networks } = user
    const catcher = (err) => {
      const { message = err.error?.message } = err

      if (!err.metadata) err.metadata = { email: user.docId, message }

      const error = http.catch(err)

      const errorMessage = message || 'Error Unknown'

      // Replace error message's ephemeral snackbar with a persistent dialog.
      commit('tools/snackbar', true, { root: true })
      commit('tools/snack', { message: errorMessage, status: 'error' }, { root: true })

      return error
    }

    const getOrgConfigByBillingCode = () => {
      const billingCodes = networks.reduce((billingCodesArray, network) => {
        const { organization: { code: billingCode } } = network
        if (!billingCodesArray.includes(billingCode)) billingCodesArray.push(billingCode)
        return billingCodesArray
      }, [])
      return dispatch('getOrgConfigByBillingCode', { billingCodes, catcher })
    }

    return {
      clients: isInternalUser ? dispatch('getClients') : [],
      configs: isInternalUser ? dispatch('getOrgConfig', catcher) : getOrgConfigByBillingCode(),
      publishers: dispatch('getPublishers', { isInternalUser, user }),
    }
  },
  async fetchClientsAndPublishers({ dispatch }, user) {
    const requestsByRole = await dispatch('getOrgRequestsByRole', user)

    const { clients: clientsReq, configs, publishers: publishersReq } = requestsByRole

    return Promise.all([publishersReq, clientsReq, configs, Promise.resolve(user)])
  },
  reduceClientsAndPublishers(context, { clients, configs, publishers, user }) {
    if (user.access?.role === 'internal') {
      configs.forEach((config) => {
        config.isInternal = true
      })
    }

    const makeOrgs = Organization.reduceClientsAndPublishers.bind(Organization, configs)
    const clientsAndPublishers = []

    clientsAndPublishers.push(...clients, ...publishers)

    return clientsAndPublishers.reduce(makeOrgs, [])
  },
  async transformClientsAndPublishers({ dispatch }, data) {
    const { handler: { catcher }, response } = data
    const [publishers, clients, configs, user] = response

    if (!Array.isArray(clients)) return catcher(clients)
    if (!Array.isArray(publishers)) return catcher(publishers)

    data.items = await dispatch('reduceClientsAndPublishers', { clients, configs, publishers, user })

    return data
  },
  /**
   * Flattens organizations and their teams.
   * @param {Object} context vuexContext
   * @param {Organizations} organizations
   * @return {Promise<ErrorMessage|Teams>}
   */
  async getOrganizationsAsTeams(context, organizations) {
    const error = () => !Array.isArray(organizations) && organizations
    const success = () => organizations.reduce((orgsAndTeams, org) => {
      if (org.hasTeam) {
        orgsAndTeams.push(org, ...org.teams)
      } else {
        orgsAndTeams.push(org)
      }

      return orgsAndTeams
    }, [])

    return error() || success()
  },

  getAuthedUserOrganizations({ dispatch, state }, moduleName) {
    const { user } = state
    return dispatch('getOrganizations', { moduleName, user })
  },
  async fetchPersonAndUser({ dispatch }, { catcher, params }) {
    const personsRequest = api.getPersonById(params.reportId).catch(catcher)
    const personResponse = await personsRequest
    const { content } = personResponse
    const [{ personEmail }] = (Array.isArray(content) && content) || [{ personEmail: null }]
    const usersRequest = (personEmail && dispatch('getUserByEmail', personEmail).then((user) => [user])) || {
      code: 404,
      layer: 'app/data',
      message: 'User Not Found',
      metadata: { personId: params.reportId },
    }

    return [personsRequest, usersRequest]
  },
  async fetchPersonsAndUsers({ dispatch }, { catcher, params }) {
    const haveReportId = () => params.reportId && typeof params.reportId !== 'undefined'
    const fetchPersonById = async () => params.reportKey === 'person' && haveReportId() && dispatch('fetchPersonAndUser', { catcher, params })
    const fetchPersons = async () => {
      const personsRequest = api.getPersons().catch(catcher)
      const usersRequest = dispatch('getPersonDocuments', catcher)

      return [personsRequest, usersRequest]
    }
    const promiseArray = await fetchPersonById() || await fetchPersons()

    return Promise.all(promiseArray)
  },
  async transformPersonUsers({ dispatch, rootGetters }, data) {
    const { handler, requestReport, response } = data
    const { endpointName } = requestReport
    const [personsResponse, users] = response
    const { catcher } = handler

    if (!personsResponse || !Array.isArray(personsResponse.content)) data.response.error = personsResponse
    if (!users || !Array.isArray(users)) data.response.error = users

    data.response.content = response

    const transformer = async (responses) => {
      const personColumnsByKey = rootGetters['tools/personColumnsByKey']
      const [personsRes] = responses
      const { content: persons } = personsRes
      const organizations = await dispatch('getAuthedUserOrganizations', endpointName)
      const formatAndNormalize = (item) => transform.formatAndNormalize(item, personColumnsByKey)
      const personsByEmail = persons.reduce((personsDict, person) => {
        person.isUser = false
        person.isPerson = true

        const findManagerName = () => {
          const findManager = () => persons.find((p) => p.personId === person.managerPersonId)
          const manager = person.managerPersonId && findManager()
          return manager && `${manager.personNameFirst} ${manager.personNameLast}`
        }
        const nameManagerById = () => person.managerPersonId && `Person ID ${person.managerPersonId}`
        const nameManager = () => {
          const setManagerName = () => findManagerName() || nameManagerById() || 'None'
          return person.isInternal ? setManagerName() : 'N/A'
        }

        person.managerPersonName = nameManager()

        personsDict[person.personEmail] = person

        return personsDict
      }, {})
      const personUsersByEmail = users.reduce((personUsersDict, user) => {
        const { docId: userEmail } = user
        const matchingPersonRecord = personUsersDict[userEmail]

        if (matchingPersonRecord) {
          personUsersDict[userEmail] = { ...user, ...matchingPersonRecord }
        } else {
          const defaultPerson = {
            isPerson: false,
            personId: null,
            personEmail: userEmail,
          }
          personUsersDict[userEmail] = { ...user, ...defaultPerson }
        }

        personUsersDict[userEmail].isUser = true

        return personUsersDict
      }, personsByEmail)
      const personUsers = Object.keys(personUsersByEmail).map(async (emailAddress) => {
        const personUser = personUsersByEmail[emailAddress]
        const person = User.mapPersonAndUser(personUser, organizations)
        const formatAndNormalizePerson = (viewUser, key) => formatAndNormalize(person)(key)
        return Object.keys(person).reduce(formatAndNormalizePerson, person)
      })

      return Promise.all(personUsers)
    }

    const rules = [
      () => !users && 'firebase',
      () => !personsResponse && 'api',
      () => personsResponse.error && personsResponse,
    ]
    const foundError = rules.find((fnValidate) => fnValidate(personsResponse, users))
    const catchError = () => foundError && catcher(foundError)

    data.requestReport.items = catchError() || await transformer(response)
    data.requestReport.success = data.handler.success
    data.requestReport.excluded = []

    return data.requestReport
  },

  async makeUser(context, { organizations, person, user: authedUser }) {
    const personUser = { ...authedUser, ...person }
    return User.mapPersonAndUser(personUser, organizations)
  },
  async makeSelf(context, userRecord) {
    const { docId } = userRecord
    const personResponse = await api.getPersonByEmail(docId)
    const { content, error } = personResponse

    if (error || !Array.isArray(content)) {
      return copyObject(userRecord)
    }

    const [person] = content

    return createObject(userRecord, person)
  },
  async setUser({ commit, dispatch }, records) {
    const { analyticsGoal, authedUser, catcher, credential, email } = records

    // The API has been configured by this point, so we can use it to fetch the user's person profile.
    // If the user is aliasing another user, the email address references the aliased user's email address.
    const response = await api.getPersonByEmail(email).catch(catcher)
    const { content, error } = response

    // If the API has returned an error or the response content is not an array, we throw an error.
    if (error || !Array.isArray(content)) return catcher(response)

    // If the API has returned a valid response, we destructure the person profile from the response content array.
    // We then check whether the user is authorized to access the application.
    const [person] = content
    const isAuthorizedPerson = User.isAuthorizedPerson(person)

    if (!isAuthorizedPerson) return catcher(response)

    // If the user is authorized and aliasing another user, we fetch the user's actual person profile
    // This is necessary because the aliased user's profile does not contain the current user's actual permissions.
    // We use the current user's actual permissions to determine whether the user can update their own alias state.
    authedUser.self = !!authedUser.self && await dispatch('makeSelf', authedUser.self)

    // We fetch the user's organizations, which are used to determine the user's permissions and settings.
    // If the user is aliasing another user, this is the aliased user's organization data.
    const moduleName = 'auth'
    const organizations = await dispatch('getOrganizations', { moduleName, user: authedUser })

    // We make the user's profile using their user record, person profile, and relevant organization data.
    const user = await dispatch('makeUser', { organizations, person, user: authedUser })
    const payload = { credential, user }

    // We commit the user to the store, triggering a cascade of events based on the user's permissions and settings.
    commit(actionNames.SIGN_IN_SUCCEEDED, payload)

    fireanalytics.event('store_user', analyticsGoal)

    return payload
  },
  async makeAnalyticsUser({ rootGetters }, personUser) {
    const { network } = personUser
    const networks = Organization.flattenNetworkData(network)
    const { billingCodes, orgIds, teamCodes, teamIds } = networks

    const hasPacing = await rootGetters['tools/hasPacing']
    const hasDiscrepancy = await rootGetters['tools/hasDiscrepancy']
    const hasHealthAdx = await rootGetters['tools/hasHealthAdx']
    const hasHealthNetwork = await rootGetters['tools/hasHealthNetwork']
    const hasProgrammatic = await rootGetters['tools/hasProgrammatic']
    const hasYieldPartner = await rootGetters['tools/hasYieldPartner']
    const inDemoMode = rootGetters['auth/inDemoMode']

    const {
      access,
      expires,
      isDeleted,
      managerPersonId,
      personId,
    } = personUser
    const { active, level, role } = access || {}
    const version = process.env.VUE_APP_VERSION

    return {
      active,
      billingCodes: billingCodes.join(', '),
      expires,
      hasDiscrepancy,
      hasHealthAdx,
      hasHealthNetwork,
      hasProgrammatic,
      hasYieldPartner,
      hasPacing,
      isDeleted,
      inDemoMode,
      level,
      managerPersonId,
      orgIds: orgIds.join(', '),
      personId,
      role,
      teamCodes: teamCodes.join(', '),
      teamIds: teamIds.join(', '),
      version,
    }
  },
  async setSentryMonitoring(context, { analyticsUser, personId }) {
    const { billingCodes, inDemoMode, level, role, teamCodes } = analyticsUser
    const { hostname } = window.document.location
    const nodeEnv = process.env.NODE_ENV
    const project = process.env.VUE_APP_GOOGLE_PROJECT
    const version = process.env.VUE_APP_VERSION

    logger.sentry.setUser({ billingCodes, inDemoMode, level, personId, role, teamCodes })
    logger.sentry.setTags({ hostname, nodeEnv, project, version })

    return analyticsUser
  },
  setGoogleAnalytics(context, analyticsData) {
    return fireanalytics.setUser(analyticsData)
  },
  async monitorUser({ dispatch }, personUser) {
    const analyticsUser = await dispatch('makeAnalyticsUser', personUser)
    const { personId = personUser.docId } = personUser
    const analyticsData = { analyticsUser, personId }

    dispatch('setGoogleAnalytics', analyticsData)
    dispatch('setSentryMonitoring', analyticsData)

    return analyticsUser
  },
  async setAppData({ dispatch }, appData) {
    const userData = await dispatch('setUser', appData)
    const { user } = userData

    if (user) {
      dispatch('monitorUser', user)
    }

    return userData
  },
  async setCredential(context, auth) {
    const { credential, user: fireUser } = auth
    const authGrant = credential || fireUser
    const token = await authGrant.getIdToken()

    http.setAuthorizationHeader(token)

    return authGrant
  },
  async setHttpRequests({ dispatch }, { catcher, firestoreUser, userData }) {
    const isDemoUserRequest = dispatch('setDataAccess', firestoreUser)
    const credentialRequest = dispatch('setCredential', userData)
    const promises = Promise.all([isDemoUserRequest, credentialRequest])
    const [isDemoUser, credential] = await promises.catch(catcher)

    return { credential, isDemoUser }
  },
  async storeUser({ dispatch }, data) {
    const { analyticsGoal, catcher, firestoreUser, googleProfile, userData } = data

    fireanalytics.event('update_user', {
      category: analyticsGoal.category,
      description: analyticsGoal.description,
      label: 'Store User Requested',
    })

    const setHttpRequestsArgs = { catcher, firestoreUser, userData }
    const requests = await dispatch('setHttpRequests', setHttpRequestsArgs)

    if (!requests) {
      const error = { code: '403', layer: 'app', message: 'Requests Denied' }

      fireanalytics.event('exception', {
        category: analyticsGoal.category,
        description: analyticsGoal.description,
        label: `${analyticsGoal.description} Failed to Store User with Error "Requests Denied"`,
      })

      return catcher({ error })
    }

    const { credential } = requests || {}

    const authedUser = { ...firestoreUser, ...googleProfile }
    const appData = { analyticsGoal, authedUser, catcher, credential, email: googleProfile.email }

    return dispatch('setAppData', appData)
  },
  async getUserRecords({ commit }, { catcher, fireUser }) {
    const { email, providerData: [googleProfile] } = fireUser

    commit(actionNames.SIGN_IN_REQUESTED, email)

    const firestoreUser = await firecollection.get('person', email, catcher)

    return { firestoreUser, googleProfile }
  },
  async setupSignedInUser({ commit, dispatch }, { action, fireUser }) {
    const analyticsEvent = 'Sign In'

    fireanalytics.event('goal_initialization', {
      category: 'Auth',
      description: analyticsEvent,
      label: `${analyticsEvent} Requested via ${action}`,
    })

    const catcher = (err) => {
      const error = http.catch(err)

      commit(actionNames.SIGN_IN_FAILED, error)

      fireanalytics.event('exception', {
        category: 'Auth',
        description: analyticsEvent,
        label: `${analyticsEvent} via ${action} Failed with Error "${error.message}"`,
      })

      return error
    }

    const { firestoreUser, googleProfile } = await dispatch('getUserRecords', { catcher, fireUser })
    const errors = User.authorizeUser(firestoreUser)

    if (errors.length > 0) {
      const metadata = { email: fireUser.email, errors }

      const error = { code: '403', layer: 'app', message: 'Access Denied', metadata }

      return catcher({ error })
    }

    const aliasId = User.aliasId(firestoreUser)
    const setAlias = async () => {
      const aliasUser = await firecollection.get('person', aliasId, catcher)
      const self = copyObject(firestoreUser)

      fireUser.aliasId = aliasId
      firestoreUser.aliasId = aliasId

      fireUser.self = self
      firestoreUser.self = self

      fireUser.access = aliasUser.access
      fireUser.network = aliasUser.network
      fireUser.settings = aliasUser.settings

      firestoreUser.access = aliasUser.access
      firestoreUser.network = aliasUser.network
      firestoreUser.settings = aliasUser.settings

      // To emulate users before activating them, we ignore alias.access.active.
      fireUser.access.active = true
      firestoreUser.access.active = true

      fireanalytics.event('goal_progress', {
        category: 'Auth',
        description: 'Sign In',
        label: `Set Alias Mode to ${analyticsEvent} via ${action} Succeeded`,
      })

      // The docId/email is untouched because calls to the API require it.
      return { user: fireUser }
    }
    const userData = (aliasId && await setAlias()) || { user: fireUser }
    const analyticsGoal = {
      category: 'Auth',
      description: 'Sign In',
      label: `Store User to Sign In via ${action} Succeeded`,
    }

    const storedData = dispatch('storeUser', { analyticsGoal, catcher, firestoreUser, googleProfile, userData })

    if (storedData && storedData.user) {
      fireanalytics.event('goal_completion', {
        category: 'Auth',
        description: 'Sign In',
        label: `Sign In via ${action} Succeeded`,
      })
    }

    return storedData
  },
  async signOut({ commit }, action = 'Unknown') {
    commit(actionNames.SIGN_OUT_REQUESTED)

    fireanalytics.event('goal_initialization', {
      category: 'Auth',
      description: 'Sign Out Requested',
      label: `Sign Out Requested via ${action}`,
    })

    const catcher = (err) => {
      const error = http.catch(err)

      commit(actionNames.SIGN_OUT_FAILED, error)

      fireanalytics.event('exception', {
        category: 'Auth',
        description: 'Sign Out Failed',
        label: `Sign Out via ${action} Failed with Error "${error.message}"`,
      })

      return error
    }

    await fireauth.signOut().catch(catcher)

    commit(actionNames.SIGN_OUT_SUCCEEDED)

    fireanalytics.event('goal_completion', {
      category: 'Auth',
      description: 'Sign Out Succeeded',
      label: `Sign Out via ${action} Succeeded`,
    })

    http.clearAuthToken()

    return true
  },
  async autoAuth({ commit, dispatch }, fireUser) {
    commit(actionNames.AUTH_CHECK_REQUESTED)

    const haveUser = fireUser && typeof fireUser === 'object'
    const analyticsEvent = `Sign ${(haveUser && 'In') || 'Out'}`
    const action = 'Page Load'

    fireanalytics.event('goal_initialization', {
      category: 'Auth',
      description: 'Auto Auth',
      label: `Attempt to ${analyticsEvent} via ${action} Requested`,
    })

    const signIn = () => haveUser && dispatch('setupSignedInUser', { action, fireUser })
    const signOut = () => dispatch('signOut', action)

    await (signIn() || signOut())

    fireanalytics.event('goal_completion', {
      category: 'Auth',
      description: 'Auto Auth',
      label: `Attempt to ${analyticsEvent} via ${action} Completed`,
    })

    commit(actionNames.AUTO_AUTH_COMPLETE)
  },
  isShowingHelp(context, payload) {
    context.commit(actionNames.SHOW_HELP, payload)
  },
  getHelp(context) {
    context.commit(actionNames.SHOW_HELP, true)
  },
  hideHelp(context) {
    context.commit(actionNames.SHOW_HELP, false)
  },
}

export default actions
